import React from "react";
import { Box, ImageList, ImageListItem, Typography } from "@mui/material";

import { importAll } from "../../utils/imageHandling";

const images = importAll(
  require.context("./images", false, /\.(png|jpe?g|JPE?G|svg)$/)
);

const Gallery = () => {
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        Gallery
      </Typography>
      <hr style={{ width: "100%" }} />
      <Box sx={{ width: "80%", height: "100%" }}>
        <ImageList variant="masonry" cols={1} gap={8}>
          {Object.values(images).map((item) => (
            <ImageListItem key={item}>
              <img
                src={item}
                srcSet={item}
                alt="Structure Damages"
                loading="lazy"
                style={{ borderRadius: "5%" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
};

export default Gallery;
