import React from "react";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar/Navbar";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";

// Pages
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Services from "./pages/Services/Services";
import Team from "./pages/Team/Team";
import Contact from "./pages/Contact/Contact";
import Careers from "./pages/Careers/Careers";
import Gallery from "./pages/Gallery/Gallery";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh"
      >
        <Navbar />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          margin="1rem"
          flexGrow="1"
          maxWidth="70%"
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
