import React from "react";
import { Typography } from "@mui/material";

const Careers = () => {
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        Careers
      </Typography>
      <hr style={{ width: "100%" }} />
      <Typography variant="body1" align="justify">
        Please check back for career opportunities.
      </Typography>
    </>
  );
};

export default Careers;
