import React from "react";
import { Typography } from "@mui/material";

const PageNotFound = () => {
  return (
    <>
      <Typography>Oops! Page not found.</Typography>
    </>
  );
};

export default PageNotFound;
