import React from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import styled from "@emotion/styled";
import nationwide from "../../images/nationwide.png";
import theme from "../../theme";

// Core modules imports are same as usual
import { Autoplay, Navigation, Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { importAll } from "../../utils/imageHandling";

const StyledImg = styled.img`
  width: 100%;
`;

const images = importAll(
  require.context("../Gallery/images", false, /\.(png|jpe?g|JPE?G|svg)$/)
);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "30%",
  },
  [theme.breakpoints.up("xl")]: {
    width: "20%",
  },
};

const PhotoCarousel = () => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
        }}
        width={"100%"}
      >
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation, Pagination]}
          className="mySwiper"
          centeredSlides={true}
          loop={true}
          style={{ width: "100%", height: "100%" }}
        >
          {Object.values(images).map((item) => (
            <SwiperSlide key={item}>
              <StyledImg src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
        }}
        width={"100%"}
      >
        <Swiper
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation, Pagination]}
          className="mySwiper"
          centeredSlides={true}
          loop={true}
          style={{ width: "100%", height: "100%" }}
        >
          {Object.values(images).map((item) => (
            <SwiperSlide key={item}>
              <StyledImg src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
};

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Box display="flex">
        <Typography variant="h4" align="center">
          G&A Engineering Consultants
        </Typography>
      </Box>
      <hr style={{ width: "100%" }} />
      <Box
        display="flex"
        justifyContent="center"
        sx={{ maxHeight: "50rem", width: "100%" }}
      >
        <PhotoCarousel />
      </Box>
      <Box>
        <hr style={{ width: "100%" }} />
        <Typography variant="h4" align="center">
          Licensed to Work Across All 50 States
        </Typography>
        <hr style={{ width: "100%" }} />
        <Typography variant="body1" align="justify">
          G&A Engineering Consultants provides nationwide forensic engineering
          services, including investigation of a wide variety of structures and
          dealing with many different types of losses. Our engineers provide
          well-written, technically complete but easily understandable reports.
          <br />
          <br />
          <em>Every</em> report is signed and sealed by a professional engineer
          that is licensed in the state where the loss occurred. These reports
          also undergo a thorough review process, including engineering review
          as well as a review by technical writing experts to ensure that the
          report clearly and concisely addresses the appropriate issues.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ margin: "2rem 0" }}>
        <img src={nationwide} alt="building" width="100%" />
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            margin: "2rem",
            textAlign: "center",
          }}
          onClick={handleOpen}
        >
          Submit an Assignment
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="assignment-modal-title"
          aria-describedby="assignment-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="assignment-modal-title" variant="h6" component="h2">
              Submit an Assignment
            </Typography>
            <Typography
              id="assignment-modal-description"
              variant="body1"
              sx={{ mt: 2 }}
            >
              Email info to assignments@gaecgroup.com
            </Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Home;
