import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import pages from "../../routes";

const XsFooter = styled(Container)`
  flex-direction: column;
  align-items: center;
`;
const MdFooter = styled(Container)`
  flex-direction: row;
  column-gap: 1rem;
  justify-content: center;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  footer a {
    color: white;
  }
  padding: 1rem 0rem;
  max-width: none !important;
  margin: 0;
`;

const Footer = () => {
  const theme = useTheme();
  const date = new Date();
  const navigate = useNavigate();

  const handleClickRoute = (page) => {
    navigate(page.route);
  };
  return (
    <StyledContainer
      style={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <XsFooter sx={{ display: { xs: "flex", sm: "none" } }} as="footer">
        {pages.map((page) => (
          <Button
            key={page.name}
            onClick={(e) => handleClickRoute(page)}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {page.name}
          </Button>
        ))}
      </XsFooter>
      <MdFooter
        sx={{ display: { xs: "none", sm: "flex" }, backgroundColor: "primary" }}
        as="footer"
      >
        {pages.map((page) => (
          <Button
            key={page.name}
            onClick={(e) => handleClickRoute(page)}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {page.name}
          </Button>
        ))}
      </MdFooter>
      <br />
      <Box
        display="flex"
        alignItems="center"
        color={theme.palette.primary.contrastText}
        justifyContent="center"
        flexDirection={"column"}
      >
        <Typography align="center">
          <BusinessIcon
            style={{
              marginRight: "0.5rem",
              position: "relative",
              marginBottom: "-3px",
              height: "20px",
            }}
          />
          6175 Main Street, Suite 399, Frisco, Texas 75034
        </Typography>
        <Typography align="center">
          <PhoneIcon
            style={{
              marginRight: "0.5rem",
              position: "relative",
              marginBottom: "-3px",
              height: "20px",
            }}
          />
          <a href="tel:855-339-7749">855-339-7749</a>
        </Typography>
      </Box>
      <br />
      <Typography
        align="center"
        style={{ color: theme.palette.primary.contrastText }}
      >
        &copy; {date.toDateString().slice(-4)} G&A Engineering Consultants
      </Typography>
    </StyledContainer>
  );
};

export default Footer;
