import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#BEB7B1",
    },
    secondary: {
      main: "#500000",
    },
    text: {
      primary: "#000000",
    },
  },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export default theme;
