import React from "react";
import { Typography, Box } from "@mui/material";
import GaecIcon from "../../images/gaec.svg";
import styled from "@emotion/styled";

const StyledImg = styled.img`
  width: 60%;
  margin-right: 1em;
`;

const About = () => {
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        About Us
      </Typography>
      <hr style={{ width: "100%" }} />
      <Typography variant="body1" align="justify">
        G&A Engineering Consultants offers forensic structural engineering
        services nationwide. We have a network of licensed professional
        engineers that are experienced in all types of structures and have
        investigated many different types of losses, including floods,
        tornadoes/wind damage, hail/roof losses, wind/water claims,
        blast/vibration claims, and many others. We support all phases of claim
        investigation from initial evaluation of damage through expert witness
        testimony as needed. <br />
        <br />
        G&A was founded in 2001 by John D. Garner, PE, and we are based in
        Frisco, Texas. We have both direct and contract professional engineers
        ready to accept assignments nationwide. We have engineers licensed to
        practice in all 50 states, as well as cooperative agreements with
        engineers in Puerto Rico and the US Virgin Islands. Our firm also
        carries all necessary state firm licensure or certificates of
        authorization to perform engineering work in all 50 states as well.
      </Typography>
      <Box display="flex" justifyContent="center" sx={{ margin: "2rem 0rem" }}>
        <StyledImg src={GaecIcon} alt="company logo"></StyledImg>
      </Box>
    </>
  );
};

export default About;
