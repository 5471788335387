import React from "react";
import styled from "@emotion/styled";
import { Typography, Box, Paper } from "@mui/material";
import JohnGImage from "./headshots/JohnGarner.png";
import RandyImage from "./headshots/RandyVanWinkle.jpg";
import JohnImage from "./headshots/JohnSteele.jpg";
import AudraImage from "./headshots/AudraTurner.jpg";
import ChristineImage from "./headshots/christine-conner.jpg";

const StyledImg = styled.img`
  max-width: 100%;
  border-radius: 5%;
`;

const people = [
  {
    name: "John D. Garner, P.E.",
    title: "President/Senior Principal Engineer",
    bio: [
      "John Garner founded G&A Engineering Consultants in 2001, and is the president and senior principal engineer of the firm. John is one of the few to have achieved licensure to practice engineering in all 50 states.",
      "John has been working as a structural engineer for 33 years. He began his career in the engineering/construction field, performing structural designs for large industrial projects. For the last 28 years, he has specialized in forensic structural engineering, with experience with all types of structures and all sorts of losses.",
      "John attended the University of Texas at Austin, and he remains a diehard fan. In addition, he obtained a Master of Business Administration from the University of Houston. He enjoys playing basketball, hiking, and traveling. Fun fact about John: he has passed through almost 400 different airports in his lifetime.",
    ],
    imgPath: JohnGImage,
  },
  {
    name: "Christine Conner, P.E.",
    title: "Principal Engineer",
    bio: [
      "Christine Conner joined G&A Engineering Consultants in 2023 as a Principal Engineer. She is licensed as a professional engineer in Alabama, Arkansas, Florida, Kansas, Louisiana, Mississippi, Minnesota, Tennessee, and Texas. She has over 10 years of experience in structural engineering and forensic assessments.",
      "Christine started her career in the design and construction of residential, multiple-family, and light commercial buildings, focusing on structural analysis and construction site visits, as well as post-construction failure evaluations. For the past 5 years, she has specialized in forensic structural engineering, with experience and exposure to failure and loss analysis of a wide array of residential, commercial, and large loss projects.",
      "Christine graduated from Texas A&M University with a Bachelor of Science in Civil Engineering. She has been a member of various professional and engineering organizations, including the American Society of Civil Engineers (ASCE), the National Society of Professional Engineers (NSPE), and the Foundation Performance Association (FPA). Outside of work, Christine loves traveling, trying new foods, binge-watching tv series and movies (particularly of the true crime and horror genres), attending Texas Stars hockey games, and watching football.",
    ],
    imgPath: ChristineImage,
  },
  {
    name: "John D. Steele, P.E.",
    title: "Senior Engineer",
    bio: [
      "In addition to his hands-on building design and investigative work at Steele Engineering, John has served as an adjunct college instructor in the field, with over 30 years in the design, construction, and facilities industry.  Throughout his career, John has regularly worked with residential and commercial building owners, managers, and contractors to help identify and resolve their architectural and structural building concerns and  deficiencies.",
      "In his spare time, John loves riding horses and hiking with his wife and three daughters.  Fun fact about John, he's recently started competing in gymkhana (amateur rodeo) events.",
    ],
    imgPath: JohnImage,
  },
  {
    name: "Randy L. Van Winkle, P.E.",
    title: "Senior Forensic Engineer",
    bio: [
      "Mr. Van Winkle founded Van Winkle Engineering in 1982 and has been active in consulting engineering for over 39 years. He is licensed and currently practicing in 20 states.",
      "Randy began his career designing bridges, buildings, water and wastewater treatment facilities, subdivisions and roads. His practice has evolved into primarily forensic engineering over the past 30 years. He has performed over 5,000 forensic evaluations, including with both civil and structural losses.",
      "Randy attended the University of Iowa for both undergraduate and graduate school and is a Hawkeye sports fan. He regularly plays racquetball and golf. He enjoys foreign cultures and has traveled to 40 countries (mostly to visit grandchildren).",
    ],
    imgPath: RandyImage,
  },
  {
    name: "Audra Turner",
    title: "Office Manager",
    bio: [
      "Audra Turner joined G&A Engineering Consultants in the summer of 2015.  Audra has over 25 years of office experience.  She began her career as a general office assistant and has a diverse background with experience in accounting, credit management, customer service, telecom phone programming and project management.",
      "Audra started at G&A Engineering as an Administrative Assistant and over the course of her tenure has progressed into the role of Office Manager.  She strives to enhance G&A Engineering’s customer service by utilizing her skills in project management and process development.",
      "Audra attended Brookhaven College and Collin College focusing on Business Administration.  She enjoys all types of crafts and traveling.  Her favorite travel destination is the beach, she loves snorkeling and exploring any type of water activity.",
    ],
    imgPath: AudraImage,
  },
];

const GridBlock = ({ person }) => {
  return (
    <Paper
      variant="elevation"
      elevation={0}
      sx={{
        display: "flex",
        padding: "1rem",
        flexDirection: "column",
        alignItems: "center",
        width: "20rem",
      }}
    >
      {!!person.imgPath && (
        <StyledImg
          src={person.imgPath}
          alt={"Image of " + person.name}
        ></StyledImg>
      )}
      <hr style={{ width: "100%" }} />
      <Typography variant="subtitle1" align="center">
        {person.name}
      </Typography>
      <Typography variant="subtitle2" align="center">
        {person.title}
      </Typography>
      <hr style={{ width: "100%" }} />
      <Typography variant="caption" align="justify">
        {person.bio.map((paragraph) => (
          <span key={paragraph}>
            {paragraph}
            <br />
            <br />
          </span>
        ))}
      </Typography>
    </Paper>
  );
};

const Team = () => {
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        Our Team
      </Typography>
      <hr style={{ width: "100%" }} />
      <Box
        display="flex"
        flexWrap="wrap"
        columnGap="1rem"
        rowGap="0rem"
        justifyContent="center"
      >
        {people.map((person) => (
          <GridBlock person={person} />
        ))}
      </Box>
    </>
  );
};

export default Team;
