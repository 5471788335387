import React from "react";
import { Typography, Box } from "@mui/material";
import nationwide from "../../images/nationwide.png";

const Services = () => {
  const services = [
    "CAT & Storm Response",
    "Flood/Moisture Intrusion Assessments",
    "Wind vs. Water Investigations",
    "Tornado Damage Assessments",
    "Residential/Commercial Roof Evaluations",
    "Cladding & Fenestrations Inspections",
    "Foundation Evaluations",
    "Seismic, Fire, Blast & Impact Inspections",
    "Structural Damage Assessments",
    "Construction Defects & Repair Recommendations",
    "Expert Testimony",
  ];
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        Services
      </Typography>
      <hr style={{ width: "100%" }} />
      <Typography variant="body1" align="justify">
        We have extensive experience in residential, commercial, and industrial
        structures, as well as farm and coastal structures. <br />
        <br />
        The issues that we address include but are not limited to:
        <ul>
          {services.map((service) => (
            <li>{service}</li>
          ))}
        </ul>
      </Typography>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        Licensed Nationwide
      </Typography>
      <hr style={{ width: "100%" }} />
      <Box display="flex" justifyContent="center" sx={{ margin: "1rem 0" }}>
        <img src={nationwide} alt="building" width="100%" />
      </Box>
    </>
  );
};

export default Services;
