import React from "react";
import { Box, Typography } from "@mui/material";
import building from "./building_small.jpg";

const Contact = () => {
  return (
    <>
      <hr style={{ width: "100%" }} />
      <Typography variant="h4" align="center">
        Contact Us
      </Typography>
      <hr style={{ width: "100%" }} />
      <Box>
        <Typography variant="body2">
          <Box display="flex" columnGap="2rem" flexWrap="wrap" rowGap="2rem">
            <Box>
              <Typography variant="h6">Mailing Address</Typography>
              <Typography variant="body1">
                G&A Engineering Consultants LLC
                <br />
                6175 Main Street
                <br />
                Suite 399
                <br />
                Frisco, Texas 75034
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">Phone</Typography>
              <Typography variant="body1">
                <a href="tel:855-339-7749">855-339-7749</a>
                <br />
                Local: <a href="tel:469-333-3960">469-333-3960</a>
                <br />
                Fax: <a href="tel:469-333-3967">469-333-3967</a>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">Email</Typography>{" "}
              <Typography variant="body1">
                <a href="mailto: contact@gaecgroup.com">
                  contact@gaecgroup.com
                </a>
              </Typography>
            </Box>
          </Box>
        </Typography>
      </Box>
      <br />
      <Box>
        <img src={building} alt="building" width="100%" />
      </Box>
    </>
  );
};

export default Contact;
