export const pages = [
  { name: "Home", route: "/" },
  { name: "About Us", route: "/about" },
  { name: "Our Team", route: "/team" },
  { name: "Services", route: "/services" },
  { name: "Gallery", route: "/gallery" },
  { name: "Contact Us", route: "/contact" },
  { name: "Careers", route: "/careers" },
];

export default pages;
